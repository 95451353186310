import React from "react";
import { Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import Checks from "./routes/Checks";
import Reports from "./routes/Reports";
import settings from "./routes/settings";
import Status from "./routes/status";
import Badge from "./routes/Badge";
import Explore from "./routes/Explore";
import Export from "./routes/Export";
import FreshidSSOSuccess from "./routes/freshidSSOSuccess";
import SlackIntegrationRouter from "./routes/SlackIntegrationRouter";
import SlackIntegrationSuccess from "./routes/SlackIntegrationSuccess";
import tokenLogin from "./routes/tokenLogin";
import freshidLogout from "./routes/freshidLogout";

function checkIndexAuthorization() {
  return (nextState, replace, next) => {
    if (window.localStorage.getItem("token")) {
      replace("/");
      return next();
    }
    replace("login");
    return next();
  };
}

const PageLoading = () => <h1>Loading...</h1>;

const LoginAsync = Loadable({
  loader: () => import("./routes/Login"),
  loading: PageLoading
});

const App = ({ store }) => (
  <div className="App">
    <Switch>
      <Route
        onEnter={checkIndexAuthorization(store)}
        exact
        path="/login/:export_id?/"
        component={LoginAsync}
      />
      <Route
        onEnter={checkIndexAuthorization(store)}
        exact
        path="/"
        component={LoginAsync}
      />
      <Route
        onEnter={checkIndexAuthorization(store)}
        exact
        path="/"
        component={Checks}
      />
      <Route
        onEnter={checkIndexAuthorization(store)}
        exact
        path="/dashboard"
        component={Checks}
      />
      <Route
        onEnter={checkIndexAuthorization(store)}
        path="/reports"
        component={Reports}
      />
      <Route
        onEnter={checkIndexAuthorization(store)}
        path="/settings"
        component={settings}
      />
      <Route
        onEnter={checkIndexAuthorization(store)}
        path="/status"
        component={Status}
      />
      <Route
        onEnter={checkIndexAuthorization(store)}
        path="/badge"
        component={Badge}
      />
      <Route
        onEnter={checkIndexAuthorization(store)}
        path="/explore"
        component={Explore}
      />
      <Route
        onEnter={checkIndexAuthorization(store)}
        path="/org/download-export/:export_id?/"
        component={Export}
      />
      <Route
        onEnter={checkIndexAuthorization(store)}
        path="/org/"
        component={LoginAsync}
      />
      <Route
        path="/freshid/oauth/success"
        component={FreshidSSOSuccess}
      />
      <Route
        path="/slack/oauth/:domain_name"
        component={SlackIntegrationRouter}
      />
      <Route
        path="/slack/oauth"
        component={SlackIntegrationSuccess}
      />
      <Route
        path="/token-login/:auth_token/:organization_id/"
        component={tokenLogin}
      />
      <Route
        path="/freshid/logout/"
        component={freshidLogout}
      />
    </Switch>
  </div>
);

export default App;
