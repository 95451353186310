import React from 'react';
import { request } from '../utils/Api';


class Export extends React.Component {
  constructor(props) {
    super(props);
    const { export_id } = this.props.match.params;
    this.state = {
      export_id: export_id
    };
  }

    componentDidMount() {
    const token = localStorage.getItem("auth-token");
      if(token && this.state.export_id){
        const exportDownload = async () => {
          try {
            const migrateInitiate = await request(
              `/export/download?export_id=${this.state.export_id}`,
              { method: 'GET' }
            );
            if (migrateInitiate.url) {
              window.location.href = migrateInitiate.url;
              setTimeout(() => {
                window.close();
              }, 5000);
            } else {
              console.error("No URL provided for file download.");
            }
          } catch (error) {
            console.error("Error during file download initiation:", error);
          }
        };
        exportDownload();
      }else{
        window.location.href = `${window.location.origin}/login/${this.state.export_id}`;
      }

    }
  render() {
    return <div />
  }
}

export default Export;
