import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import CautionOutline from '../images/caution_outline.svg';
import { getOrgId } from '../utils/utility';
import { request } from '../utils/Api';

const MigrationBanner = () => {
  const [showContinue, setShowContinueButton] = useState(true)
  useEffect(()=>{
    const getExportStatus = async () => {
        const migrateInitiateStatus = await request(`/export`, {
          method: 'GET',
        });
        if(["COMPLETE","IN PROGRESS"].includes(migrateInitiateStatus.status)){
          setShowContinueButton(false);
      }
    } 
    getExportStatus();
  },[]);

  const initiateExport = async () => {
    const payload = {
      organization: getOrgId()
    };
    const migrateInitiate = await request(`/export`, {
      method: 'POST',
      payload
    });
    if(migrateInitiate.message){
      setShowContinueButton(false);
    } 
  };

  return (
    <StyledMigrationBanner>
      <div className="caution-icon">
        <img src={CautionOutline} alt="" role="presentation" />
      </div>
      <p className="content">
        30 days to go, Freshping will be retiring on March 1, 2025. Your data is ready for an export now.
      </p>
      {showContinue && <button class='btn-as-link' type='button' onClick={initiateExport}>
        Continue
      </button>}
      
    </StyledMigrationBanner>
  );
};

export default MigrationBanner;

const StyledMigrationBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 14px;
  font-size: 14px;
  font-weight: 700;
  background-color: #ffe9ee;
  color: #27313b;
  box-shadow: inset 0px -1px 0px #b0d5fc;
  margin-bottom: 8px;

  .caution-icon {
    width: 20px;
    height: 20px;
  }

  .content {
    margin: 0;
  }

  .btn-as-link {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: #2e66d0;
  }
`;
